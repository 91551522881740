import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import { computarCincoUltimosAnos } from "../../../../shared/libs/utils";
import PropTypes from "prop-types";

export default class SeletorAno extends Component {
  static propTypes = {
    onAtualizarAno: PropTypes.func
  };

  constructor(props) {
    super(props);

    const anos = computarCincoUltimosAnos();
    const anoAtivo = anos[anos.length - 1];
    this.state = { anos, anoAtivo };
  }

  __handleClick = ano => {
    this.setState({ anoAtivo: ano });
    this.props.onAtualizarAno(ano);
  };

  render() {
    const { anos, anoAtivo } = this.state;
    const botoes = [];
    for (let ano of anos) {
      botoes.push(
        <Grid.Column key={ano} style={{ textAlign: "center" }}>
          <Button
            toggle
            active={ano === anoAtivo}
            color="blue"
            style={{ margin: 10 }}
            onClick={this.__handleClick.bind(null, ano)}
          >
            <span>{ano}</span>
          </Button>
        </Grid.Column>
      );
    }

    return (
      <Grid container columns={anos.length + 6}>
        <Grid.Column />
        <Grid.Column />
        <Grid.Column />
        {botoes}
        <Grid.Column />
        <Grid.Column />
        <Grid.Column />
      </Grid>
    );
  }
}
