import React, { Component } from "react";

import { bool, func, string } from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import {
  withLoader,
  loaderPropTypes
} from "../../../../shared/components/loader/LoaderContext";
import { withMessages } from "../../../../shared/components/message/MessageContext";
import {
  ERROR,
  SUCCESS,
  WARNING
} from "../../../../shared/components/message/MessageType";
import { competenciaParaString } from "../../../../shared/libs/utils";

const LOADER_ID = "carregar-dados";

class AcoesCarregarDados extends Component {
  static propTypes = {
    ...loaderPropTypes,
    disabled: bool,
    competencia: string,
    serie: string,
    acaoGerarOcorrencia: func
  };

  __handleClick = async () => {
    const { setLoading, addMessage, serie, competencia } = this.props;
    setLoading(this.getLoaderId(), true);
    let resultado;
    try {
      let msgCompetencia = "";
      if (competencia) {
        resultado = await this.props.acaoGerarOcorrencia(serie, competencia);
        msgCompetencia = `da competência ${competenciaParaString(
          competencia
        )}, serie ${serie} `;
      }

      if (resultado) {
        if (resultado.status === "FINALIZADO") {
          addMessage(
            SUCCESS,
            `Dados ${msgCompetencia}carregados e enviados com sucesso.`
          );
        } else if (resultado.status === "SEM_DADOS") {
          addMessage(
            WARNING,
            `Não há dados ${msgCompetencia}para serem carregados e enviados.`
          );
        } else if (resultado.status === "ERRO") {
          addMessage(
            WARNING,
            `Ocorreu um erro ao buscar os dados ${msgCompetencia}para serem carregados e enviados. Antes de tentar nova carga, verifique se todos os dados já carregados foram processados.`
          );
        } else if (resultado.status === "EXECUTANDO") {
          addMessage(
            WARNING,
            `Já existem eventos em fila e/ou em processamento. Antes de tentar nova carga, aguarde até que todos os dados já carregados sejam processados pela EFD-Reinf (o processamento pode demorar).`
          );
        }
      }
    } catch (error) {
      let errorMessage = error;
      if (error.message) {
        errorMessage = error.message;
      }
      addMessage(ERROR, errorMessage);
    } finally {
      setLoading(this.getLoaderId(), false);
      window.scrollTo(0, 0);
    }
  };

  getLoaderId = () => {
    return `${LOADER_ID}${this.props.competencia}`;
  };

  render() {
    const { disabled, getLoading } = this.props;
    return (
      <Button
        icon
        labelPosition="left"
        color="green"
        floated="right"
        style={{ marginRight: 10 }}
        disabled={disabled || getLoading(this.getLoaderId()).active}
        loading={getLoading(this.getLoaderId()).active}
        onClick={this.__handleClick}
      >
        <Icon name="sync" />
        <span>Carregar e enviar dados</span>
      </Button>
    );
  }
}

export default withMessages(withLoader(AcoesCarregarDados));
