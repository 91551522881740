import Keycloak from "keycloak-js";
import defaultConfig from "./default-config";

const keycloakUrl =
  window.env && window.env.KEYCLOAK_URL
    ? window.env.KEYCLOAK_URL
    : defaultConfig.keycloak.url;
const keycloakRealm =
  window.env && window.env.KEYCLOAK_REALM
    ? window.env.KEYCLOAK_REALM
    : defaultConfig.keycloak.realm;
const keycloakClientId =
  window.env && window.env.KEYCLOAK_CLIENTID
    ? window.env.KEYCLOAK_CLIENTID
    : defaultConfig.keycloak.clientId;

export const keycloak = new Keycloak({
  url: keycloakUrl,
  realm: keycloakRealm,
  clientId: keycloakClientId
});
