import React, { Component } from "react";
import { Popup } from "semantic-ui-react";

import OnflineIcon, {
  OnflineStatus
} from "../../../../shared/components/OnflineIcon";
import { statusReinfGov } from "../../../api/reinf-jt";

import defaultConfig from "../../../../shared/libs/default-config";

const tempoUpdate =
  window.env &&
  window.env.TEMPO_UPDATE_SERVICO_REINF &&
  !isNaN(window.env.TEMPO_UPDATE_SERVICO_REINF) &&
  window.env.TEMPO_UPDATE_SERVICO_REINF > 0
    ? window.env.TEMPO_UPDATE_SERVICO_REINF
    : defaultConfig.tempoUpdateServicoReinf;

export default class StatusReinfRF extends Component {
  state = {
    status: OnflineStatus.Fetching
  };

  __checkStatus = async () => {
    try {
      const response = await statusReinfGov();
      this.setState({
        status:
          response.status === "UP"
            ? OnflineStatus.Online
            : OnflineStatus.Offline
      });
    } catch (error) {
      this.setState({ status: OnflineStatus.Unknown });
    }
  };

  componentDidMount() {
    this.checkStatusInterval = setInterval(this.__checkStatus, tempoUpdate);
    this.__checkStatus();
  }

  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }

  render() {
    const { status } = this.state;
    const statusIcon = (
      <span style={{ margin: "0 0.2rem" }}>
        <OnflineIcon status={status} />
        efd-Reinf
      </span>
    );
    return (
      <Popup
        inverted
        trigger={statusIcon}
        content={`efd-Reinf (Receita Federal): ${status.descricao}`}
      />
    );
  }
}
