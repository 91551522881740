import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak, temPermissao } from "../shared/libs/keycloak-auth";
import { Route } from "react-router-dom";
import Factory from "./Factory";
import DashboardRoute from "./components/dashboard/DashboardRoute";
import OcorrenciaDetalhadaRoute from "./components/ocorrencia/OcorrenciaDetalhadaRoute";
import PrivateRoute from "../shared/components/PrivateRoute";

export default function App() {
  return (
    <Factory>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
      >
        <PrivateRoute>
          <Route
            exact
            path="/"
            component={DashboardRoute}
            onEnter={temPermissao}
          />
          <Route
            path="/ocorrencia/:id"
            component={OcorrenciaDetalhadaRoute}
            onEnter={temPermissao}
          />
        </PrivateRoute>
      </ReactKeycloakProvider>
    </Factory>
  );
}
