import { shape, string, number } from "prop-types";
import log from "loglevel";

export const tipoEventoShape = shape({
  id: number,
  descricao: string,
  descricaoCompleta: string
});

export default class TipoEvento {
  static criarComDadosApi(ocorrenciaApi) {
    const tipoEvento = new TipoEvento();
    const tipoEventoApi = TipoEvento.__tipoEventoFromOcorrenciaApi(
      ocorrenciaApi
    );
    if (tipoEventoApi) {
      tipoEvento.id = tipoEventoApi.id;
      if (tipoEvento.id === 2010) {
        if (ocorrenciaApi.cno) {
          tipoEvento.descricao = `CNO: ${ocorrenciaApi.cno}`;
        } else {
          tipoEvento.descricao = `CNPJ: ${ocorrenciaApi.cnpj}`;
        }
      } else if (tipoEvento.id === 4020) {
        tipoEvento.descricao = `CNPJ: ${ocorrenciaApi.cnpj}`;
      } else {
        log.info("Convertendo evento " + tipoEventoApi);
        tipoEvento.descricao = tipoEventoApi.descricao.toUpperCase();
        tipoEvento.id = tipoEventoApi.id;
      }
    }

    return tipoEvento;
  }

  static __tipoEventoFromOcorrenciaApi(ocorrenciaApi) {
    if (ocorrenciaApi.tipoEvento) {
      return ocorrenciaApi.tipoEvento;
    } else if (ocorrenciaApi.evento) {
      return ocorrenciaApi.evento.tipoEvento;
    } else {
      return null;
    }
  }

  get descricaoCompleta() {
    if (this.id && this.descricao) {
      return `R-${this.id} - ${this.descricao}`;
    } else {
      return "Evento em preparação";
    }
  }
}
