import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { useKeycloak } from "@react-keycloak/web";

export default function BotaoLogin() {
  const { keycloak } = useKeycloak();
  const getUserName = () => {
    return keycloak.tokenParsed.name
      ? keycloak.tokenParsed.name
      : keycloak.tokenParsed.given_name
      ? keycloak.tokenParsed.given_name
      : keycloak.tokenParsed.preferred_username;
  };
  return (
    <div>
      {!keycloak.authenticated && (
        <Button animated onClick={() => keycloak.login()}>
          <Button.Content visible>Login</Button.Content>
        </Button>
      )}

      {!!keycloak.authenticated && (
        <div>
          {getUserName() + " "}
          <Icon circular name="user" />
          <Icon
            name="logout"
            onClick={() => keycloak.logout()}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  );
}
