import React, { Component } from "react";

import { bool, string, func } from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import {
  withLoader,
  loaderPropTypes
} from "../../../../shared/components/loader/LoaderContext";
import { withMessages } from "../../../../shared/components/message/MessageContext";
import { ERROR } from "../../../../shared/components/message/MessageType";

const LOADER_ID = "fecharCompetencia";

class AcaoCompetencia extends Component {
  static propTypes = {
    ...loaderPropTypes,
    competencia: string,
    serie: string,
    labelButton: string,
    disabled: bool,
    habilitado: bool,
    color: string,
    acao: func,
    icon: string
  };

  __handleClick = async () => {
    const { setLoading, addMessage, competencia, serie, acao } = this.props;
    setLoading(this.getLoaderId(), true);
    try {
      acao(competencia, serie);
    } catch (error) {
      let errorMessage = error;
      if (error.message) {
        errorMessage = error.message;
      }
      addMessage(ERROR, errorMessage);
      window.scrollTo(0, 0);
    } finally {
      //setLoading(this.getLoaderId(), false);
    }
  };

  getLoaderId = () => {
    return `${LOADER_ID}${this.props.competencia}`;
  };

  render() {
    const { disabled, getLoading, habilitado, color, icon } = this.props;
    return (
      <Button
        color={color}
        icon
        labelPosition="right"
        floated="right"
        disabled={
          disabled || getLoading(this.getLoaderId()).active || !habilitado
        }
        loading={getLoading(this.getLoaderId()).active}
        onClick={e => {
          e.stopPropagation();
          this.__handleClick();
        }}
      >
        <Icon name={icon} />
        <span>{this.props.labelButton}</span>
      </Button>
    );
  }
}

export default withMessages(withLoader(AcaoCompetencia));
