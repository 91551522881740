import React from "react";
import { Container, Header } from "semantic-ui-react";
import BotaoLogin from "./BotaoLogin";

export default function AcessoNegado() {
  return (
    <Container text style={{ marginTop: "100px", textAlign: "center" }}>
      <Header as="h1">Acesso negado</Header>
      <p>Seu usuário não tem permissão para acessar o efd-Reinf.</p>
      <p>
        Clique <a href="javascript:history.back()">aqui</a> para voltar, ou use
        o botão abaixo para trocar de usuário.
      </p>
      <p>
        <BotaoLogin />
      </p>
    </Container>
  );
}
