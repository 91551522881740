export function filter(haystack, needle) {
  if (!haystack || !needle) return true;
  return ("" + haystack).toLowerCase().indexOf(needle.toLowerCase()) >= 0;
}

export function filterAny(needle, ...haystacks) {
  return haystacks
    .filter(haystack => haystack)
    .reduce((found, haystack) => found || filter(haystack, needle), false);
}

export async function sleep(timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

export function computarAnoAtual() {
  return new Date(Date.now()).getFullYear();
}

/**
 * Retorna um número correspondente ao mês atual, 1-based (i.e. Janeiro === 1).
 *
 * @returns Número correspondente ao mẽs atual (1-BASED!)
 */
export function computarMesAtual() {
  return new Date(Date.now()).getMonth() + 1;
}

export function computarCompetenciaAtual() {
  const mesAtual = computarMesAtual();
  const anoAtual = computarAnoAtual();
  return formatarCompetencia(anoAtual, mesAtual);
}

export function lpadMes(dado) {
  return ("00" + dado).slice(-2);
}

export function computarCincoUltimosAnos() {
  const anoAtual = computarAnoAtual();
  const cincoUltimosAnos = [];
  for (let i = anoAtual; i > anoAtual - 5; i--) {
    cincoUltimosAnos.push(i);
  }
  cincoUltimosAnos.sort();
  return cincoUltimosAnos;
}

function formatarCompetencia(ano, numeroMes) {
  const mes = lpadMes(numeroMes);
  return `${ano}-${mes}`;
}

export function computarCompetenciasDoAno(anoTemp) {
  const anoAtual = computarAnoAtual();
  const ano = anoTemp || anoAtual;
  const competencias = [];
  let ultimoMes = 0;
  if (ano < anoAtual) {
    ultimoMes = 12;
  } else if (ano === anoAtual) {
    ultimoMes = computarMesAtual();
  }
  for (let i = ultimoMes; i > 0; i--) {
    competencias.push(formatarCompetencia(ano, i));
  }
  return competencias;
}

export function obterAnoDaCompetencia(competencia) {
  return competencia.substring(0, 4);
}

const meses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro"
];

export function competenciaParaString(competencia) {
  const ano = obterAnoDaCompetencia(competencia);
  const mes = obterMesDaCompetencia(competencia);
  return meses[mes].concat("/").concat(ano);
}

function obterMesDaCompetencia(competencia) {
  return parseInt(competencia.substring(5)) - 1;
}
