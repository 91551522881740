const defaultConfig = {
  keycloak: {
    url: "https://auth-dev.sigeo.jt.jus.br/auth",
    realm: "jt",
    clientId: "reinf-public",
    authorizedRoles: ["GESTORREINF"]
  },
  urlExtrator: "http://localhost:8888/reinf-jt-extrator",
  urlConector: "http://localhost:8080/reinf-jt-service",
  tempoUpdateCompetencia: 5000,
  tempoUpdateServicosJT: 10000,
  tempoUpdateServicoReinf: 300000
};

export default defaultConfig;
