const FECHAR_COMPETENCIA_SUCESSO =
  "O Evento de Fechamento foi enviado com sucesso. Aguardando o processamento deste evento pelo EFD-Reinf.";
const FECHAR_COMPETENCIA_ERRO =
  "Erro ao fechar competência! Verifique se todos os módulos estão online e tente novamente mais tarde.";
const REABRIR_COMPETENCIA_SUCESSO =
  "Evento de reabertura de competência enviado com sucesso ao EFD-Reinf.";
const REABRIR_COMPETENCIA_ERRO =
  "Erro ao reabrir competência! Verifique se todos os módulos estão online e tente novamente mais tarde.";

const MENSAGENS = {
  FECHAR_COMPETENCIA_SUCESSO,
  FECHAR_COMPETENCIA_ERRO,
  REABRIR_COMPETENCIA_SUCESSO,
  REABRIR_COMPETENCIA_ERRO
};

export default MENSAGENS;
