import { func } from "prop-types";
import { any } from "prop-types";
import React from "react";
import { Tab } from "semantic-ui-react";

function AbaTipoEvento(props) {
  const panes = [
    {
      menuItem: "Eventos 2000",
      pane: <Tab.Pane key="e2000">{props.children}</Tab.Pane>
    },
    {
      menuItem: "Eventos 4000",
      pane: <Tab.Pane key="e4000">{props.children}</Tab.Pane>
    } /* ,
    {
      menuItem: "Eventos de Tabela",
      pane: <Tab.Pane key="eTabela">{props.children}</Tab.Pane>
    } */
  ];

  return (
    <Tab
      panes={panes}
      renderActiveOnly={false}
      onTabChange={props.onTabChangeHandler}
    />
  );
}

AbaTipoEvento.propTypes = {
  children: any,
  onTabChangeHandler: func
};

export default AbaTipoEvento;
