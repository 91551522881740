import React, { Component } from "react";
import { Popup } from "semantic-ui-react";

import OnflineIcon, {
  OnflineStatus
} from "../../../../shared/components/OnflineIcon";
import { statusReinfJt } from "../../../api/reinf-jt";
import defaultConfig from "../../../../shared/libs/default-config";

const tempoUpdate =
  window.env &&
  window.env.TEMPO_UPDATE_SERVICOS_JT &&
  !isNaN(window.env.TEMPO_UPDATE_SERVICOS_JT) &&
  window.env.TEMPO_UPDATE_SERVICOS_JT > 0
    ? window.env.TEMPO_UPDATE_SERVICOS_JT
    : defaultConfig.tempoUpdateServicosJT;

export default class StatusConector extends Component {
  state = {
    status: OnflineStatus.Fetching
  };

  __checkStatus = async () => {
    try {
      const response = await statusReinfJt();
      this.setState({
        status:
          response.status === "UP"
            ? OnflineStatus.Online
            : OnflineStatus.Offline
      });
    } catch (error) {
      this.setState({ status: OnflineStatus.Offline });
    }
  };

  componentDidMount() {
    this.checkStatusInterval = setInterval(this.__checkStatus, tempoUpdate);
    this.__checkStatus();
  }

  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }

  render() {
    const { status } = this.state;
    const statusIcon = (
      <span style={{ margin: "0 0.2rem" }}>
        <OnflineIcon status={status} />
        Conector
      </span>
    );
    return (
      <Popup
        inverted
        trigger={statusIcon}
        content={`Conector: ${status.descricao}`}
      />
    );
  }
}
