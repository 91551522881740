import React, { Fragment } from "react";
import { Menu, Responsive } from "semantic-ui-react";

import AcoesProducaoRestrita from "./AcoesProducaoRestrita";
import BotaoLogin from "../../shared/BotaoLogin";
import { verificaProducaoRestrita } from "../../../api/reinf-jt";

function ListaAcoes() {
  const isProducaoRestrita = verificaProducaoRestrita().then(
    emProducaoRestrita => {
      return emProducaoRestrita;
    }
  );

  return (
    <Responsive as={Fragment} minWidth={768}>
      <Menu.Item position="right">
        {/* <AcoesCarregarDados /> */}
        {isProducaoRestrita === "true" ? <AcoesProducaoRestrita /> : ""}
        <BotaoLogin />
      </Menu.Item>
    </Responsive>
  );
}

export default ListaAcoes;
