import uniqid from "uniqid";
import { format } from "date-fns";

export default class ErroEnvioOcorrencia {
  constructor(id, titulo, descricao, localizacao = null) {
    this.id = id;
    this.titulo = titulo;
    this.descricao = descricao;
    this.localizacao = localizacao;
  }

  static listarErrosOcorrenciaUltimoEnvio(ocorrencia) {
    if (!ocorrencia.erroInterno && !ocorrencia.erroProcessamento) {
      return null;
    } else {
      const listaErros = [];

      if (ocorrencia.erroInterno) {
        listaErros.push(
          ...ocorrencia.erroInterno.map(
            erro =>
              new ErroEnvioOcorrencia(
                uniqid(),
                `Erro interno em ${format(erro.data, "DD/MM/YYYY")}`,
                erro.descricao
              )
          )
        );
      }

      if (ocorrencia.erroProcessamento) {
        listaErros.push(
          ...ocorrencia.erroProcessamento.map(
            erro =>
              new ErroEnvioOcorrencia(
                uniqid(),
                `Erro de processamento em ${format(erro.data, "DD/MM/YYYY")}`,
                erro.descricao
              )
          )
        );
      }

      return listaErros;
    }
  }
}
