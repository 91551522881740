import { func, number, string } from "prop-types";
import {
  computarCompetenciaAtual,
  computarCompetenciasDoAno
} from "../../../shared/libs/utils";

import { executarPorSerieCompetencia, buscarR1070 } from "../../api/reinf-jt";

import React, { Fragment, useState, useEffect } from "react";
import { Grid, Menu } from "semantic-ui-react";

import BaseRow from "../layout/BaseRow";
import BaseLayout from "../layout/BaseLayout";
import ListaAcoes from "./acoes/ListaAcoes";
import SeletorAno from "./seletor-ano/SeletorAno";
import StatusConector from "./status/StatusReinfJt";
import StatusReinfRF from "./status/StatusReinfGov";
import StatusExtrator from "./status/StatusConector";
import Competencia from "./competencia/Competencia";
import AbaTipoEvento from "./seletor-aba/AbaTipoEvento";
import TabelaOcorrencias from "./pesquisa/TabelaOcorrencias";

function MenuItems() {
  return (
    <Fragment>
      <Menu.Item>
        <StatusConector />
        <StatusReinfRF />
        <StatusExtrator />
      </Menu.Item>
      <ListaAcoes />
    </Fragment>
  );
}

function Dashboard({ onAtualizarAno, ano }) {
  const [dados, setDados] = useState([]);
  const [pagina, setPagina] = useState(1);
  const isEnvio = false;
  const [serie, setSerie] = useState("2000");
  const [tabAtiva, setTabAtiva] = useState(0);
  const [competencias, setCompetencias] = useState([]);
  const [competenciaAtiva, setCompetenciaAtiva] = useState(
    computarCompetenciaAtual()
  );

  const acaoGerarOcorrencia = (s, c) => executarPorSerieCompetencia(s, c);

  const onChangeTipoEvento = (e, data) => {
    setTabAtiva(data.activeIndex);
    if (data.activeIndex == 0) {
      setSerie("2000");
    } else if (data.activeIndex == 1) {
      setSerie("4000");
    } /*else {
      setSerie("1070");
    }*/
  };

  const setEstadoCompetencia = (competencia, ativa) => {
    const competenciaAtiva = ativa ? competencia : "";
    setCompetenciaAtiva(competenciaAtiva);
  };

  useEffect(() => {
    setCompetencias(computarCompetenciasDoAno(ano));
  }, [ano]);

  useEffect(() => {
    criarCompetenciasTela();
  }, [competencias]);

  useEffect(() => {
    criarCompetenciasTela();
  }, [competenciaAtiva]);

  useEffect(() => {
    if (tabAtiva == 2) {
      // setAcaoGerarOcorrencia(() => );
      buscarR1070(pagina).then(resultado => {
        setDados(dados => {
          dados = [];
          dados.push(
            <BaseRow>
              <TabelaOcorrencias
                dados={resultado}
                carregarPagina={setPagina}
                isEnvio={isEnvio}
              />
            </BaseRow>
          );
        });
      });
    } else {
      criarCompetenciasTela();
    }
  }, [tabAtiva]);

  const criarCompetenciasTela = () => {
    setDados(dados => {
      dados = [];

      for (let competencia of competencias) {
        const indice = competencias.indexOf(competencia);
        const ativo = competencia === competenciaAtiva;

        dados.push(
          <BaseRow key={indice}>
            <Competencia
              indice={indice}
              competencia={competencia}
              serie={serie}
              ativo={ativo}
              acaoGerarOcorrencia={acaoGerarOcorrencia}
              setEstadoCompetencia={setEstadoCompetencia}
            />
          </BaseRow>
        );
      }

      return dados;
    });
  };

  return (
    <BaseLayout menuItems={<MenuItems />}>
      <AbaTipoEvento onTabChangeHandler={onChangeTipoEvento}>
        <SeletorAno onAtualizarAno={onAtualizarAno} />
        <Grid
          container
          padded="vertically"
          stackable
          style={{ marginBottom: "2rem" }}
        >
          {dados}
        </Grid>
      </AbaTipoEvento>
    </BaseLayout>
  );
}

Dashboard.propTypes = {
  onSelectSumario: func,
  filtroEstado: string,
  onAtualizarAno: func,
  ano: number
};

export default Dashboard;
