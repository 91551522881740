import React, { Component } from "react";
import Dashboard from "./Dashboard";
import { withMessages } from "../../../shared/components/message/MessageContext";
import { computarAnoAtual } from "../../../shared/libs/utils";

class DashboardRoute extends Component {
  state = {
    anoSelecionado: computarAnoAtual()
  };

  atualizarAno = ano => {
    this.setState({ anoSelecionado: ano });
  };

  render() {
    return (
      <Dashboard
        onAtualizarAno={this.atualizarAno}
        ano={this.state.anoSelecionado}
      />
    );
  }
}

export default withMessages(DashboardRoute);
