import { keycloak } from "../../shared/libs/keycloak-auth";
import Ocorrencia from "../models/ocorrencia/Ocorrencia";
import defaultConfig from "../../shared/libs/default-config";
import log from "loglevel";

const baseUrl =
  window.env && window.env.REINF_SERVICE_URL
    ? window.env.REINF_SERVICE_URL
    : defaultConfig.urlConector;

function obterHeadersComAutenticacao(headers) {
  const finalHeaders = headers || new Headers();
  finalHeaders.append("Authorization", "Bearer ".concat(keycloak.token));
  return finalHeaders;
}

export function statusReinfJt() {
  return fetch(`${baseUrl}/actuator/health`)
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function statusReinfGov() {
  const headers = obterHeadersComAutenticacao();
  return fetch(`${baseUrl}/actuator/reinfhealth`, {
    method: "GET",
    headers
  })
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function limparProducaoRestrita() {
  const headers = obterHeadersComAutenticacao();
  return fetch(`${baseUrl}/producao-restrita/acoes/limpar`, {
    method: "POST",
    headers
  })
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function verificaProducaoRestrita() {
  const headers = obterHeadersComAutenticacao();
  return fetch(`${baseUrl}/producao-restrita/`, {
    method: "GET",
    headers
  })
    .then(response => response.text())
    .catch(error => log.error(error));
}

export function obterOcorrenciaDetalhada(id) {
  const headers = obterHeadersComAutenticacao();
  return fetch(`${baseUrl}/ocorrencias/detalhe/${id}`, {
    method: "GET",
    headers
  })
    .then(response => response.json())
    .then(Ocorrencia.criarComDadosApi)
    .catch(error => log.error(error));
}

export function executarPorSerieCompetencia(serie, competencia) {
  const headers = obterHeadersComAutenticacao();
  headers.append("Content-Type", "application/json");
  return fetch(`${baseUrl}/extrator/executar/${serie}/${competencia}`, {
    method: "POST",
    headers
  })
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function executarR1070() {
  const headers = obterHeadersComAutenticacao();
  headers.append("Content-Type", "application/json");
  const unidade = keycloak.tokenParsed.UNIDADE_ORCAMENTARIA;
  return fetch(
    `${baseUrl}/extrator/executarEventosTabela?tenantId=${unidade}`,
    {
      method: "POST",
      headers
    }
  )
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function buscarR1070(pagina) {
  const headers = obterHeadersComAutenticacao();
  return fetch(
    `${baseUrl}/ocorrencias/dados-basicos?serie=1070&page=${pagina}`,
    {
      method: "GET",
      headers
    }
  )
    .then(response => response.json())
    .then(page => {
      const ocorrencias = page.content;

      if (ocorrencias) {
        page.content = ocorrencias.map(Ocorrencia.criarComDadosApi);
        return page;
      }
      return { content: [], empty: true };
    })
    .catch(error => {
      log.error(error);
      return { content: [], empty: true };
    });
}

export function fecharCompetencia(competencia, serie) {
  const headers = obterHeadersComAutenticacao();
  headers.append("Content-Type", "application/json");
  return fetch(
    `${baseUrl}/extrator/fecharCompetencia/${serie}/${competencia}`,
    {
      method: "POST",
      headers
    }
  )
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function reabrirCompetencia(competencia, serie) {
  const headers = obterHeadersComAutenticacao();
  headers.append("Content-Type", "application/json");
  return fetch(
    `${baseUrl}/extrator/reabrirCompetencia/${serie}/${competencia}`,
    {
      method: "POST",
      headers
    }
  )
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function verificarStatusCompetencia(competencia, serie) {
  const headers = obterHeadersComAutenticacao();
  headers.append("Content-Type", "application/json");
  return fetch(`${baseUrl}/ocorrencias/status/${serie}/${competencia}`, {
    method: "GET",
    headers
  })
    .then(response => response.json())
    .catch(error => log.error(error));
}

export function validarFechamentoCompetencia(competencia, serie) {
  const headers = obterHeadersComAutenticacao();
  headers.append("Content-Type", "application/json");
  return fetch(`${baseUrl}/ocorrencias/validar/${serie}/${competencia}`, {
    method: "GET",
    headers
  })
    .then(response => response.text())
    .then(msg => {
      if (msg === null) fecharCompetencia(competencia);
      else {
        return msg;
      }
    })
    .catch(error => log.error(error));
}

export async function listarOcorrencias(competencia, serie, pagina) {
  const headers = obterHeadersComAutenticacao();
  return fetch(
    `${baseUrl}/ocorrencias/dados-basicos?competencia=${competencia}&serie=${serie}&page=${pagina}`,
    {
      method: "GET",
      headers
    }
  )
    .then(response => response.json())
    .then(page => {
      const ocorrencias = page.content;
      if (ocorrencias) {
        page.content = ocorrencias.map(Ocorrencia.criarComDadosApi);
        return page;
      }
      return { content: [], empty: true };
    })
    .catch(error => {
      log.error(error);
      return { content: [], empty: true };
    });
}

export async function listarEnvios(competencia, serie, pagina) {
  const headers = obterHeadersComAutenticacao();
  return fetch(
    `${baseUrl}/ocorrencias/envio/dados-basicos?competencia=${competencia}&serie=${serie}&page=${pagina}`,
    {
      method: "GET",
      headers
    }
  )
    .then(response => response.json())
    .then(page => {
      const ocorrencias = page.content;
      page.content = ocorrencias.map(Ocorrencia.criarComDadosApi);
      return page;
    })
    .catch(error => {
      log.error(error);
      return { content: [], empty: true };
    });
}
