import defaultConfig from "../../shared/libs/default-config";
import log from "loglevel";

const baseUrl =
  window.env && window.env.EXTRATOR_ETL_SERVICE_URL
    ? window.env.EXTRATOR_ETL_SERVICE_URL
    : defaultConfig.urlExtrator;

export function statusConector() {
  return fetch(`${baseUrl}/actuator/health`)
    .then(response => response.json())
    .catch(error => log.error(error));
}

export async function conectorUp() {
  return fetch(`${baseUrl}/actuator/health`)
    .then(response => response.ok)
    .catch(error => {
      log.error(error);
      return false;
    });
}
