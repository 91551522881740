import { func, number, bool } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { AutoSizer, Column, Table } from "react-virtualized";
import { Popup, Icon, Button, Pagination } from "semantic-ui-react";
import { pageShape } from "../../../models/ocorrencia/Ocorrencia";
import LabelEstadoOcorrencia from "../../shared/LabelEstadoOcorrencia";

const style = {
  header: {
    textTransform: "none"
  }
};

function BotaoVisualizarOcorrencia({ id }) {
  return (
    <Popup
      inverted
      trigger={
        <Button
          as={Link}
          target="_blank"
          to={`/ocorrencia/${id}`}
          icon={<Icon name="eye" />}
        />
      }
      content="Visualizar detalhes"
      position="top center"
    />
  );
}

BotaoVisualizarOcorrencia.propTypes = {
  id: number
};

function TabelaOcorrencias({ dados, carregarPagina, isEnvio }) {
  return (
    <div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Table
            width={width}
            height={460}
            headerHeight={50}
            headerStyle={style.header}
            rowHeight={40}
            rowCount={dados.content.length}
            rowGetter={({ index }) => dados.content[index]}
          >
            <Column width={100} label="ID" dataKey="id" />
            <Column
              width={900}
              label="Tipo"
              dataKey="tipoEvento"
              cellRenderer={({ cellData: tipoEvento }) =>
                tipoEvento.descricaoCompleta
              }
            />

            <Column
              width={isEnvio ? 180 : 0}
              label="Competência"
              dataKey="periodoApuracao"
            />
            <Column width={200} label="Data geração" dataKey="textoData" />
            <Column
              width={300}
              label="Situação"
              dataKey="estado"
              cellRenderer={({ cellData: estado }) => (
                <LabelEstadoOcorrencia estado={estado} />
              )}
            />
            <Column
              width={100}
              label="Ações"
              dataKey="id"
              cellRenderer={({ cellData: id }) => (
                <BotaoVisualizarOcorrencia id={id} />
              )}
            />
          </Table>
        )}
      </AutoSizer>
      <Pagination
        defaultActivePage={dados.number + 1}
        totalPages={dados.totalPages}
        onPageChange={(evento, { activePage }) =>
          carregarPagina(activePage - 1)
        }
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" />,
          icon: true
        }}
        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
      />
    </div>
  );
}

TabelaOcorrencias.propTypes = {
  dados: pageShape,
  carregarPagina: func,
  isEnvio: bool
};

export default TabelaOcorrencias;
