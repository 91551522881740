import React from "react";
import { any } from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import AcessoNegado from "../../app/components/shared/AcessoNegado";
import defaultConfig from "../libs/default-config";

const authorizedRoles =
  window.env && window.env.KEYCLOAK_AUTHORIZED_ROLES
    ? window.env.KEYCLOAK_AUTHORIZED_ROLES
    : defaultConfig.keycloak.authorizedRoles;

const isAuthorized = tokenParsed => {
  const roles = tokenParsed.realm_access.roles;
  let isAuthorized = false;
  for (let role of authorizedRoles) {
    isAuthorized = isAuthorized || roles.includes(role);
  }
  return isAuthorized;
};

const PrivateRoute = ({ children }) => {
  const { keycloak } = useKeycloak();
  const isLoggedIn =
    keycloak.authenticated && isAuthorized(keycloak.tokenParsed);

  if (keycloak.authenticated === undefined) return <div />;

  return isLoggedIn ? children : <AcessoNegado />;
};

PrivateRoute.propTypes = {
  children: any
};

export default PrivateRoute;
