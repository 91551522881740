import React, { useState, useEffect } from "react";
import { string, bool, func, number } from "prop-types";
import { Accordion, Icon, Container, Confirm } from "semantic-ui-react";
import { competenciaParaString } from "../../../../shared/libs/utils";
import {
  listarOcorrencias as carregarOcorrencias,
  listarEnvios as carregarEnvios,
  reabrirCompetencia,
  fecharCompetencia,
  validarFechamentoCompetencia,
  verificarStatusCompetencia
} from "../../../api/reinf-jt";
import TabelaOcorrencias from "../pesquisa/TabelaOcorrencias";
import defaultConfig from "../../../../shared/libs/default-config";
import {
  withMessages,
  messagePropTypes
} from "../../../../shared/components/message/MessageContext";
import AcaoCompetencia from "../acoes/AcaoCompetencia";
import Mensagens from "../../../../shared/components/message/Messages";
import {
  ERROR,
  SUCCESS,
  WARNING
} from "../../../../shared/components/message/MessageType";
import AcoesCarregarDados from "../acoes/AcoesCarregarDados";
import log from "loglevel";
import { withLoader } from "../../../../shared/components/loader/LoaderContext";

const tempoAtualizacao =
  window.env &&
  window.env.TEMPO_UPDATE_COMPETENCIA &&
  !isNaN(window.env.TEMPO_UPDATE_COMPETENCIA) &&
  window.env.TEMPO_UPDATE_COMPETENCIA > 0
    ? window.env.TEMPO_UPDATE_COMPETENCIA
    : defaultConfig.tempoUpdateCompetencia;

function Competencia(props) {
  const [dados, setDados] = useState({ content: [], empty: true });
  const isEnvio = false;
  const [isAccordion, setAccordion] = useState(true);
  const [pagina, setPagina] = useState(0);
  const [statusCompetencia, setStatusCompetencia] = useState({});
  const [dialog, setDialog] = useState({
    open: false,
    titulo: "Fechar Competência",
    msg: ""
  });

  const _agendarAtualizacaoEstadoCompetencia = () => {
    setTimeout(() => {
      _carregarStatusCompetencia(true);
    }, 5000);
  };

  const pararLoadCompetencia = () => {
    props.setLoading("fecharCompetencia" + props.competencia, false);
  };

  const handleReabrirCompetencia = () => {
    log.info(
      "Reabrindo competência " + props.competencia + " serie " + props.serie
    );
    reabrirCompetencia(props.competencia, props.serie)
      .then(resposta => {
        pararLoadCompetencia();
        if (resposta.status === "EXECUTANDO") {
          props.addMessage(
            WARNING,
            `Já existem eventos em fila e/ou em processamento. Antes de tentar nova carga, aguarde até que todos os dados já carregados sejam processados pela EFD-Reinf (o processamento pode demorar).`
          );
        } else if (resposta.status !== "ERRO") {
          props.addMessage(SUCCESS, Mensagens.REABRIR_COMPETENCIA_SUCESSO);
        } else {
          props.addMessage(ERROR, Mensagens.REABRIR_COMPETENCIA_ERRO);
        }
        _agendarAtualizacaoEstadoCompetencia();
      })
      .catch(erro => {
        props.addMessage(
          ERROR,
          Mensagens.REABRIR_COMPETENCIA_ERRO + "\n" + erro
        );
      });
  };
  const handleConfirmFecharCompetencia = () => {
    log.info(
      "Fechando competência " + props.competencia + " serie " + props.serie
    );
    fecharCompetencia(props.competencia, props.serie)
      .then(resposta => {
        if (resposta.status === "EXECUTANDO") {
          props.addMessage(
            WARNING,
            `Já existem eventos em fila e/ou em processamento. Antes de tentar nova carga, aguarde até que todos os dados já carregados sejam processados pela EFD-Reinf (o processamento pode demorar).`
          );
        } else if (resposta.status !== "ERRO") {
          props.addMessage(SUCCESS, Mensagens.FECHAR_COMPETENCIA_SUCESSO);
        } else {
          props.addMessage(ERROR, Mensagens.FECHAR_COMPETENCIA_ERRO);
        }
        _agendarAtualizacaoEstadoCompetencia();
      })
      .catch(erro => {
        props.addMessage(
          ERROR,
          Mensagens.FECHAR_COMPETENCIA_ERRO + "\n" + erro
        );
      })
      .finally(() => {
        setDialog({ ...dialog, open: false });
        window.scrollTo(0, 0);
      });
  };
  const handleCancelFecharCompetencia = () => {
    setDialog({ ...dialog, open: false });
  };
  const validarFecharCompetencia = async (competencia, serie) => {
    log.info("Validando fechamento de competência " + competencia);
    validarFechamentoCompetencia(competencia, serie).then(msg => {
      pararLoadCompetencia();
      if (msg !== null) {
        setDialog({ ...dialog, msg: msg, open: true });
      } else {
        props.addMessage(SUCCESS, Mensagens.FECHAR_COMPETENCIA_SUCESSO);
        window.scrollTo(0, 0);
      }
    });
  };

  const statusCompetencias = [
    {
      id: 0,
      labelButton: "Fechar competência",
      acao: validarFecharCompetencia,
      color: "red",
      habilitado: true,
      icon: "lock"
    },
    {
      id: 1,
      labelButton: "Reabrir competência",
      acao: handleReabrirCompetencia,
      color: "blue",
      habilitado: true,
      icon: "unlock"
    },
    {
      id: 2,
      labelButton: "Fechando competência",
      acao: null,
      color: "red",
      habilitado: false,
      icon: "lock"
    },
    {
      id: 3,
      labelButton: "Reabrindo competência",
      acao: null,
      color: "blue",
      habilitado: false,
      icon: "unlock"
    }
  ];

  const mostrarTabelaOcorrencias = () => {
    let resultado;
    if (dados && !dados.empty) {
      resultado = (
        <TabelaOcorrencias
          dados={dados}
          carregarPagina={setPagina}
          isEnvio={isEnvio}
        />
      );
    } else if (dados && dados.empty) {
      resultado = (
        <h3 style={{ textAlign: "center" }}>
          Não há ocorrências nesta competência
        </h3>
      );
    } else {
      resultado = <div />;
    }
    return resultado;
  };

  const isUltimoEventoFechamento = () => {
    return (
      (statusCompetencia.tipoEvento &&
        (statusCompetencia.tipoEvento.id === 2099 ||
          statusCompetencia.tipoEvento.id === 40990)) ||
      statusCompetencia.tipo === "FECHAMENTO" ||
      statusCompetencia.tipo === "FECHAMENTO_4k"
    );
  };

  const isUltimoEventoReabertura = () => {
    return (
      (statusCompetencia.tipoEvento &&
        (statusCompetencia.tipoEvento.id === 2098 ||
          statusCompetencia.tipoEvento.id === 40991)) ||
      statusCompetencia.tipo === "REABERTURA" ||
      statusCompetencia.tipo === "REABERTURA_4K"
    );
  };

  const getStatusCompetencia = () => {
    if (statusCompetencia) {
      if (!statusCompetencia.tipo) {
        return statusCompetencias[0];
      }
      if (isUltimoEventoFechamento()) {
        if (
          !statusCompetencia.estadoEvento ||
          statusCompetencia.estadoEvento.id === 3
        ) {
          return statusCompetencias[1];
        } else if (
          !statusCompetencia.estadoEvento ||
          statusCompetencia.estadoEvento.id == 4 ||
          statusCompetencia.estadoEvento.id == 5
        ) {
          return statusCompetencias[0];
        } else return statusCompetencias[2];
      }
      if (isUltimoEventoReabertura()) {
        if (
          !statusCompetencia.estadoEvento ||
          statusCompetencia.estadoEvento.id === 3
        ) {
          return statusCompetencias[0];
        } else if (
          !statusCompetencia.estadoEvento ||
          statusCompetencia.estadoEvento.id == 4 ||
          statusCompetencia.estadoEvento.id == 5
        ) {
          return statusCompetencias[1];
        } else return statusCompetencias[3];
      }
    }
  };

  const _carregarOcorrencias = isMounted => {
    // mudar para subscriptions / webflux
    if (!isEnvio) {
      carregarOcorrencias(props.competencia, props.serie, pagina)
        .then(dados => {
          if (isMounted) {
            setDados(dados);
          }
        })
        .then(() => {
          _carregarStatusCompetencia(isMounted);
        });
    } else {
      carregarEnvios(props.competencia, props.serie, pagina).then(dados => {
        if (isMounted) {
          setDados(dados);
        }
      });
    }
  };

  const _carregarStatusCompetencia = isMounted => {
    log.info(
      "Carregando estado da competência " +
        props.competencia +
        " serie " +
        props.serie
    );
    verificarStatusCompetencia(props.competencia, props.serie).then(
      stCompetencia => {
        if (isMounted) {
          log.info("Estado da competência " + stCompetencia);
          setStatusCompetencia(stCompetencia);
        }
      }
    );
  };

  const getCompetenciaAnoMesAtual = () => {
    const today = new Date();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm;
  };

  const deveMostrarCarregarDados = () => {
    const stCompetencia = getStatusCompetencia();
    return (
      stCompetencia &&
      stCompetencia.id === 0 &&
      props.competencia !== getCompetenciaAnoMesAtual()
    );
  };

  const deveMostrarAcoesCompetencia = () => {
    const stCompetencia = getStatusCompetencia();
    return stCompetencia && props.competencia !== getCompetenciaAnoMesAtual();
  };

  const mostrarAcoesCompetecia = () => {
    const deveExibir = deveMostrarAcoesCompetencia();
    return deveExibir ? (
      <AcaoCompetencia
        competencia={props.competencia}
        serie={props.serie}
        {...getStatusCompetencia()}
      />
    ) : (
      <React.Fragment />
    );
  };

  const mostrarCarregarDados = () => {
    const deveExibir = deveMostrarCarregarDados();
    return deveExibir ? (
      <AcoesCarregarDados
        serie={props.serie}
        competencia={props.competencia}
        acaoGerarOcorrencia={props.acaoGerarOcorrencia}
      />
    ) : (
      <React.Fragment />
    );
  };

  useEffect(() => {
    let isMounted = true;
    _carregarOcorrencias(isMounted);
    _carregarStatusCompetencia(isMounted);

    let intervalId;
    if (props.ativo) {
      intervalId = setInterval(
        () => _carregarOcorrencias(isMounted),
        tempoAtualizacao
      );
    }
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, [
    props.ativo,
    pagina,
    props.competencia,
    props.serie,
    isEnvio,
    props.statusCompetencia
  ]);

  return (
    <Container fluid>
      <Accordion fluid styled>
        <Accordion.Title
          active={props.ativo}
          index={props.indice}
          style={{ paddingRight: "7px", paddingBottom: "23px" }}
          onClick={() => {
            if (isAccordion) {
              props.setEstadoCompetencia(props.competencia, !props.ativo);
            }
            setAccordion(true);
          }}
        >
          <Icon name="dropdown" />
          {competenciaParaString(props.competencia)}
          {mostrarAcoesCompetecia()}
          {mostrarCarregarDados()}
        </Accordion.Title>
        <Accordion.Content active={props.ativo}>
          {mostrarTabelaOcorrencias()}
        </Accordion.Content>
      </Accordion>
      <Confirm
        header={dialog.titulo}
        content={dialog.msg}
        open={dialog.open}
        onCancel={handleCancelFecharCompetencia}
        onConfirm={handleConfirmFecharCompetencia}
      />
    </Container>
  );
}

Competencia.propTypes = {
  ...messagePropTypes,
  competencia: string,
  serie: string,
  indice: number,
  ativo: bool,
  setEstadoCompetencia: func,
  acaoGerarOcorrencia: func
};

export default withMessages(withLoader(Competencia));
